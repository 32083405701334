/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import { getNotificationStatusColor } from 'helpers/notificationStatusColor'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import NotificationsViewedButton from './NotificationsViewedButton'

/* Type imports ---------------------------------------- */
import type { NotificationUtilisateur } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Internal Variables ---------------------------------- */

/* Styled components ----------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

const ButtonContainer = styled.div`
  margin-top: 5px;
  text-align: right;
`

/* Component declaration ------------------------------- */
interface NotificationsTableMobileCardProps {
  notification: NotificationUtilisateur;
  handleNavigate: () => void;
}

const NotificationsTableMobileCard: React.FC<NotificationsTableMobileCardProps> = ({
  notification,
  handleNavigate,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link onClick={handleNavigate}>
            {notification.infoDossier.sinistre.referenceCompagnie}
          </Link>
          <ColoredSquareChip
            color={getNotificationStatusColor(notification.type.code)}
            smaller
          >
            {notification.type.libelle}
          </ColoredSquareChip>
        </TitleContainer>
        {`Date de l'évènement : ${DateUtils.APIStrToLocalDateString(notification.date)}`}
        <br />
        {`Assuré : ${notification.infoDossier.assure.nom}`}
        <br />
        {`N° de contrat : ${notification.infoDossier.assure.police}`}
        <br />
        {`Nature du sinistre : ${notification.infoDossier.sinistre.nature?.libelle}`}
        <br />
        {`Date du sinistre : ${DateUtils.APIStrToLocalDateString(notification.infoDossier.sinistre.date)}`}
        <br />
        <ColoredSquareChip
          color={getCaseStatusColor(notification.infoDossier.etat.code)}
          smaller
        >
          {notification.infoDossier.etat.libelle}
        </ColoredSquareChip>
        <ButtonContainer>
          <NotificationsViewedButton id={notification.id} />
        </ButtonContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default NotificationsTableMobileCard
