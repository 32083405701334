/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useAppDispatch } from 'store/hooks'
import { setAuthInfo } from 'store/slices/authSlice'
import { useAuthenticateMutation } from 'store/api'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ---------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  LoginRequest,
  LoginResponse,
} from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */
const LoginFormSchema = Yup.object().shape({
  username: Yup.string().required('Identifiant invalide'),
  password: Yup.string().required('Mot de passe invalide'),
})

/* Styled components ----------------------------------- */

/* Component declaration ------------------------------- */
interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  const [
    tryLogin,
  ] = useAuthenticateMutation()

  const onSubmit = (values: LoginRequest, { setSubmitting, setErrors }: FormikHelpers<LoginRequest> ): void | Promise<void> => {
    setErrorMessage(null)
    tryLogin(values)
      .then((pResult) => {
        setSubmitting(false)

        if (isApiError(pResult)) {
          if (pResult.error.status === 404) {
            setErrors({ username: 'Mauvais identifiant ou mot de passe.', password: 'Mauvais identifiant ou mot de passe.' })
          } else {
            setErrorMessage('Erreur lors de la connexion.')
          }
        } else if (isApiResponse<LoginResponse>(pResult)) {
          dispatch(setAuthInfo((pResult.data)))
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setErrorMessage('Erreur lors de la connexion.')
        console.error(error)
      })
  }

  const formikForm = useForm<LoginRequest>(
    {
      initialValues: {
        username: '',
        password: '',
      },
      onSubmit: onSubmit,
      validationSchema: LoginFormSchema,
    },
  )

  const onForgotPasswordClick = () => {
    navigate('/mot-de-passe-oublie')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          {'Bienvenue dans '}
          <b>
            ePass
          </b>
        </AuthWelcomeMessage>
        <AuthErrorMessage>
          {errorMessage}
        </AuthErrorMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle required>
              Nom d'utilisateur
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Identifiant"
              name="username"
              autoComplete="username"
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <FormBoldTitle required>
              Mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              name="password"
              autoComplete="current-password"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            {
              formikForm.isSubmitting ?
                `Connexion en cours...` :
                `Se connecter`
            }
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onForgotPasswordClick}
          >
            Mot de passe oublié
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default LoginPage
