/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useLazyGetNotificationListQuery,
  usePostViewedNotificationMutation,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'

/* Type imports ---------------------------------------- */

/* Types declaration ----------------------------------- */

/* Internal Variables ---------------------------------- */

/* Styled components ----------------------------------- */

/* Component declaration ------------------------------- */
interface NotificationsViewedButtonProps {
  id: string;
}

const NotificationsViewedButton: React.FC<NotificationsViewedButtonProps> = ({ id }) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const [
    submitViewed,
  ] = usePostViewedNotificationMutation()
  const [
    getNotificationList,
  ] = useLazyGetNotificationListQuery()

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setIsLoading(true)
    submitViewed(id)
      .then(() => {
        return getNotificationList()
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress size={24} /> : 'Marquer comme lu'}
    </Button>
  )
}

export default NotificationsViewedButton
