/* eslint-disable @typescript-eslint/no-unused-vars */
/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  LoginResponse,
  LoginRequest,
  CodeLabel,
  DocumentDossierDetailParams,
  EvenementielDocumentDetailParams,
  PieceJointe,
  NatureSinistre,
  CodeEtatDossier,
  CategoriePJ,
  CategoriesPjListParams,
  MesDossiersDossierListParams,
  DatesCle,
  RendezVous,
  Dossier,
  JalonSimplifie,
  InformationsGroupe,
  SuiviSimplifie,
  Risque,
  EchangeInterne,
  EchangeInterneListParams,
  DestinatairesEchangeInterneDetailParams,
  EchangeInterneRequest,
  RepondreEchangeInterneRequest,
  DossierRecherche,
  ResumeDocumentListParams,
  ResumeDocumentsDossier,
  NotificationUtilisateur,
  NotificationUtilisateurRequest,
  MonCompteEpass,
  MonCompteEpassRequest,
  PhotoProfilCompteCreatePayload,
  RechercheRapideDossierListParams,
  LoginEpassExterieurAuthentificationCreateParams,
  LoginEpassGexsiAuthentificationCreateParams,
  ChangePasswordRequest,
  ResetEpassPasswordAuthentificationCreateParams,
  ChangePasswordResetRequest,
  ResultatRechercheMesDossier,
  AgencesListParams,
  CreationDossierRequest,
  RefDossier,
  NatureExpertise,
  ExpertsListParams,
  RendezVousDossierDetailParams,
  ValidationProblemDetails,
  DocumentDossierCreatePayload,
} from 'API/__generated__/Api'

/* API Redux service ----------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, ValidationProblemDetails>,
    tagTypes: [
      'AUTH',
      'EXCHANGES',
      'MEDIAS',
      'MISSIONS',
      'PROFILE',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        authenticate: build.mutation<LoginResponse, LoginRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginEPassAuthentificationCreate,
                args,
              )
            },
            invalidatesTags: [ 'AUTH', 'EXCHANGES', 'MEDIAS', 'MISSIONS', 'PROFILE' ],
          },
        ),
        authenticateExternal: build.mutation<LoginResponse, LoginEpassExterieurAuthentificationCreateParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginEpassExterieurAuthentificationCreate,
                args,
              )
            },
            invalidatesTags: [ 'AUTH', 'EXCHANGES', 'MEDIAS', 'MISSIONS', 'PROFILE' ],
          },
        ),
        authenticateGexsi: build.mutation<LoginResponse, LoginEpassGexsiAuthentificationCreateParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginEpassGexsiAuthentificationCreate,
                args,
              )
            },
            invalidatesTags: [ 'AUTH', 'EXCHANGES', 'MEDIAS', 'MISSIONS', 'PROFILE' ],
          },
        ),
        postChangePassword: build.mutation<MonCompteEpass, ChangePasswordRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.compte.changePasswordCompteCreate,
                args,
              )
            },
          },
        ),
        postResetPassword: build.mutation<void, ResetEpassPasswordAuthentificationCreateParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.resetEpassPasswordAuthentificationCreate,
                args,
              )
            },
          },
        ),
        postResetPasswordChange: build.mutation<void, ChangePasswordResetRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.changeEpassPasswordAuthentificationCreate,
                args,
              )
            },
          },
        ),
        getGroupInfos: build.query<InformationsGroupe, void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.groupe.informationsGroupeList,
              )
            },
          },
        ),
        /* Case */
        getCaseList: build.query<ResultatRechercheMesDossier, MesDossiersDossierListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.mesDossiersDossierList,
                args,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        getCaseResearch: build.query<DossierRecherche[], RechercheRapideDossierListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.rechercheRapideDossierList,
                args,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        getCaseDocumentInfosList: build.query<ResumeDocumentsDossier[], ResumeDocumentListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.document.resumeDocumentList,
                args,
              )
            },
          },
        ),
        getCaseInfos: build.query<Dossier, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.dossierDetail,
                args,
              )
            },
          },
        ),
        getCaseKeyDates: build.query<DatesCle, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.datesCleDossierDetail,
                args,
              )
            },
          },
        ),
        getCaseWorkflow: build.query<JalonSimplifie[], string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.workflowDossierDetail,
                args,
              )
            },
          },
        ),
        /* Case Expertise Rdv */
        getCaseRendezVousList: build.query<RendezVous[], RendezVousDossierDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.rendezVousDossierDetail,
                args,
              )
            },
          },
        ),
        /* Case Contract */
        getCaseRisk: build.query<Risque, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.risqueDernierRapportDefinitifDossierDetail,
                args,
              )
            },
          },
        ),
        /* Case Follow-Up */
        getCaseHistoryList: build.query<SuiviSimplifie[], string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.historiqueDossierDetail,
                args,
              )
            },
          },
        ),
        /* Internal Exchanges */
        getInternalExchangeList: build.query<EchangeInterne[], EchangeInterneListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.echangeInterneList,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'EXCHANGES', id: 'LIST' },
              ...result.map((r) => ({ type: 'EXCHANGES' as const, id: `${r.id}` })),
            ],
          },
        ),
        getInternalExchangesRecipientList: build.query<CodeLabel[], DestinatairesEchangeInterneDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.destinatairesEchangeInterneDetail,
                args,
              )
            },
          },
        ),
        postNewInternalExchange: build.mutation<void, {caseId: string; data: EchangeInterneRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.nouveauEchangeInterneCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'EXCHANGES' ],
          },
        ),
        postAnswerInternalExchange: build.mutation<void, {id: string; data: RepondreEchangeInterneRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.repondreEchangeInterneCreate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'EXCHANGES' ],
          },
        ),
        postViewedInternalExchange: build.mutation<void, {id: string}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.marquerCommeLuEchangeInterneCreate,
                args.id,
              )
            },
            invalidatesTags: [ 'EXCHANGES' ],
          },
        ),
        postNotViewedInternalExchange: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.marquerCommeNonLuEchangeInterneCreate,
                args,
              )
            },
            invalidatesTags: [ 'EXCHANGES' ],
          },
        ),
        /* Documents */
        getDocumentCategoryList: build.query<CategoriePJ[], CategoriesPjListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.categoriesPjList,
                args,
              )
            },
          },
        ),
        getCaseDocumentList: build.query<PieceJointe[], DocumentDossierDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.documentDossierDetail,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'MEDIAS', id: 'LIST' },
              ...result.map((r) => ({ type: 'MEDIAS' as const, id: `${arg.dossier}-${r.id}` })),
            ],
          },
        ),
        getCaseEventDocumentList: build.query<PieceJointe[], EvenementielDocumentDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.evenementielDocumentDetail,
                args,
              )
            },
          },
        ),
        getCaseFilteredEventDocumentList: build.query<PieceJointe[], string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.epassDocumentDetail,
                args,
              )
            },
          },
        ),
        postDocuments: build.mutation<void, { caseId: string; data: DocumentDossierCreatePayload }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.documentDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'MEDIAS' ],
          },
        ),
        /* New Mission */
        postNewMission: build.mutation<RefDossier, CreationDossierRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.dossierCreate,
                args,
              )
            },
          },
        ),
        getMissionType: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.typesMissionList,
              )
            },
          },
        ),
        getExpertiseCaractereList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.caracteresExpertiseList,
              )
            },
          },
        ),
        getExpertiseNatureList: build.query<NatureExpertise[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.naturesExpertiseList,
              )
            },
          },
        ),
        /* Notifications */
        getNotificationList: build.query<NotificationUtilisateur[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.notificationsUtilisateur.notificationsUtilisateurList,
              )
            },
          },
        ),
        postNewNotification: build.mutation<void, NotificationUtilisateurRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.notificationsUtilisateur.notificationsUtilisateurCreate,
                args,
              )
            },
          },
        ),
        postViewedNotification: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.notificationsUtilisateur.marquerCommeLuNotificationsUtilisateurCreate,
                args,
              )
            },
          },
        ),
        /* Profile */
        getProfileInfos: build.query<MonCompteEpass, void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.compte.monCompteEpassCompteList,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'PROFILE', id: result?.email ?? '' },
            ],
          },
        ),
        postUpdateProfileInfos: build.mutation<MonCompteEpass, MonCompteEpassRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.compte.monCompteEpassCompteUpdate,
                args,
              )
            },
            invalidatesTags: [ 'PROFILE' ],
          },
        ),
        postUpdateProfilePicture: build.mutation<void, PhotoProfilCompteCreatePayload>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.compte.photoProfilCompteCreate,
                args,
              )
            },
            invalidatesTags: [ 'PROFILE' ],
          },
        ),
        /* Other SelectLists */
        getCaseStateCodeList: build.query<CodeEtatDossier[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.codesEtatDossierList,
              )
            },
          },
        ),
        getDisasterNatureList: build.query<NatureSinistre[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.naturesSinistreList,
              )
            },
          },
        ),
        getCompanyList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.compagniesList,
              )
            },
          },
        ),
        getCabinetList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.cabinetsList,
              )
            },
          },
        ),
        getAgencyList: build.query<CodeLabel[], AgencesListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.agencesList,
                args,
              )
            },
          },
        ),
        getExpertList: build.query<CodeLabel[], ExpertsListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.expertsList,
                args,
              )
            },
          },
        ),
        getPolitenessList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.formulesPolitesseList,
              )
            },
          },
        ),
        getIntituleList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.intitulesList,
              )
            },
          },
        ),
      }
    },
  },
)

/* Export hooks */
export const {
  // Auth
  useAuthenticateMutation,
  useAuthenticateExternalMutation,
  useAuthenticateGexsiMutation,
  usePostChangePasswordMutation,
  usePostResetPasswordMutation,
  usePostResetPasswordChangeMutation,
  useGetGroupInfosQuery,
  useLazyGetGroupInfosQuery,
  // Case
  useGetCaseListQuery,
  useLazyGetCaseListQuery,
  useGetCaseResearchQuery,
  useLazyGetCaseResearchQuery,
  useGetCaseDocumentInfosListQuery,
  useLazyGetCaseDocumentInfosListQuery,
  useGetCaseInfosQuery,
  useLazyGetCaseInfosQuery,
  useGetCaseKeyDatesQuery,
  useLazyGetCaseKeyDatesQuery,
  useGetCaseWorkflowQuery,
  useLazyGetCaseWorkflowQuery,
  // Case Expertise Rdv
  useGetCaseRendezVousListQuery,
  useLazyGetCaseRendezVousListQuery,
  // Case Contract
  useGetCaseRiskQuery,
  useLazyGetCaseRiskQuery,
  // Case Follow-Up
  useGetCaseHistoryListQuery,
  useLazyGetCaseHistoryListQuery,
  // Internal Exchanges
  useGetInternalExchangeListQuery,
  useLazyGetInternalExchangeListQuery,
  useGetInternalExchangesRecipientListQuery,
  useLazyGetInternalExchangesRecipientListQuery,
  usePostNewInternalExchangeMutation,
  usePostAnswerInternalExchangeMutation,
  usePostViewedInternalExchangeMutation,
  usePostNotViewedInternalExchangeMutation,
  // Documents
  usePostDocumentsMutation,
  useGetDocumentCategoryListQuery,
  useLazyGetDocumentCategoryListQuery,
  useGetCaseDocumentListQuery,
  useLazyGetCaseDocumentListQuery,
  useGetCaseEventDocumentListQuery,
  useLazyGetCaseEventDocumentListQuery,
  useGetCaseFilteredEventDocumentListQuery,
  useLazyGetCaseFilteredEventDocumentListQuery,
  // New Mission
  usePostNewMissionMutation,
  useGetMissionTypeQuery,
  useLazyGetMissionTypeQuery,
  useGetExpertiseCaractereListQuery,
  useLazyGetExpertiseCaractereListQuery,
  useGetExpertiseNatureListQuery,
  useLazyGetExpertiseNatureListQuery,
  // Notifications
  useGetNotificationListQuery,
  useLazyGetNotificationListQuery,
  // Profile
  useGetProfileInfosQuery,
  useLazyGetProfileInfosQuery,
  usePostNewNotificationMutation,
  usePostViewedNotificationMutation,
  usePostUpdateProfileInfosMutation,
  usePostUpdateProfilePictureMutation,
  // Other SelectLists
  useGetCaseStateCodeListQuery,
  useLazyGetCaseStateCodeListQuery,
  useGetDisasterNatureListQuery,
  useLazyGetDisasterNatureListQuery,
  useGetCompanyListQuery,
  useLazyGetCompanyListQuery,
  useGetCabinetListQuery,
  useLazyGetCabinetListQuery,
  useGetAgencyListQuery,
  useLazyGetAgencyListQuery,
  useGetExpertListQuery,
  useLazyGetExpertListQuery,
  useGetPolitenessListQuery,
  useLazyGetPolitenessListQuery,
  useGetIntituleListQuery,
  useLazyGetIntituleListQuery,
} = api
