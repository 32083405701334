/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import { useAppSelector } from 'store/hooks'
import { getRouterLastPath } from 'store/slices/routerHistorySlice'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { ArrowBackIosNewRounded } from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type declaration ------------------------------------ */
import type { CodeLabel } from 'API/__generated__/Api'

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 20px;

  @media ${(props) => props.theme.media.desktop} {
    flex-direction: column;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    justify-content: space-between;
  }
`

const CaseTitle = styled(Button)`
  font-weight: bold;
  text-transform: uppercase;
  font-size: .95rem;
  padding: 0px;
  gap: 5px;

  @media ${(props) => props.theme.media.desktop} {
    margin-bottom: 5px;
  }
`

/* Component declaration ------------------------------- */
interface CaseLayoutTitleStatusProps {
  caseId: string;
  status: CodeLabel | undefined;
  externalLogin: boolean;
}

const CaseLayoutTitleStatus: React.FC<CaseLayoutTitleStatusProps> = ({
  caseId,
  status,
  externalLogin,
}) => {
  const navigate = useNavigate()
  const routerLastPath: string | null = useAppSelector(getRouterLastPath)

  const handleGoBack = () => {
    if (!externalLogin) {
      routerLastPath !== null ? navigate(routerLastPath) : navigate(-1)
    }
  }

  return (
    <LayoutContainer>
      <CaseTitle
        onClick={handleGoBack}
        color="secondary"
        size="small"
      >
        {
          !externalLogin &&
            <ArrowBackIosNewRounded fontSize="small" />
        }
        {`Dossier ${caseId}`}
      </CaseTitle>
      {
        status &&
          <ColoredSquareChip
            color={getCaseStatusColor(status.code)}
            smaller
          >
            {status.libelle}
          </ColoredSquareChip>
      }
    </LayoutContainer>
  )
}

export default CaseLayoutTitleStatus
