/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Collapse,
  Card,
  CardContent,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import ExpertiseRdvPersonLine from './ExpertiseRdvPersonLine'

/* Type imports ---------------------------------------- */
import type { RendezVous } from 'API/__generated__/Api'

/* Type declarations ----------------------------------- */

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 15px 15px 0px;

  @media ${(props) => props.theme.media.tablet} {
    padding: 15px 5px 0px 10px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    padding: 15px 0px 0px 10px;
  }
`

const BlueTitle = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-bottom: -30px;
`

const TitleContainer = styled.div`
  display: flex;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 5px;
    flex-direction: column;
  }
`

const ChipContainer = styled.div`
  display: flex;
  gap: 5px;
`

const RdvTitle = styled(FormBoldTitle)`
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 20px;
`

const RdvStatusChip = styled(ColoredSquareChip)`
  min-width: 80px;
  font-size: .9rem;
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 15px;
`

/* Component declaration ------------------------------- */
interface ExpertiseRdvCardProps {
  rdv: RendezVous;
  index: number;
}

const ExpertiseRdvCard: React.FC<ExpertiseRdvCardProps> = ({
  rdv,
  index,
}) => {
  const [ expanded, setExpanded ] = useState(index === 0)

  const handleExpandClick = (): void => {
    if (rdv.participants.length === 0) {
      return
    }
    setExpanded(!expanded)
  }

  return (
    <Card>
      <CardTitleContainer onClick={handleExpandClick}>
        <TitleContainer>
          <RdvTitle>
            {
              `Rendez-vous du ${
                DateUtils.APIStrToLocalDateString(rdv.dateDebut, {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })
              }`
            }
          </RdvTitle>
          <ChipContainer>
            <RdvStatusChip color={rdv.annule ? 'grey' : rdv.aVenir ? 'orange' : 'green'}>
              {rdv.annule ? 'Annulé' : rdv.aVenir ? 'À venir' : 'Réalisé'}
            </RdvStatusChip>
            {
              rdv.typeActiviteRDV?.libelle &&
                <RdvStatusChip
                  color="primary"
                  variant="outlined"
                >
                  {rdv.typeActiviteRDV?.libelle}
                </RdvStatusChip>
            }
            {
              rdv.preneurRDV?.libelle &&
                <RdvStatusChip
                  color="primary"
                  variant="outlined"
                >
                  {rdv.preneurRDV?.libelle}
                </RdvStatusChip>
            }
          </ChipContainer>
        </TitleContainer>
        {
          rdv.participants.length > 0 &&
            <DropdownArrow expanded={expanded.toString()} />
        }
      </CardTitleContainer>
      <BoldSeparator />
      {
        rdv.participants.length > 0 &&
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <CardContent>
              <BlueTitle>
                Convoqués
              </BlueTitle>
              {
                rdv.participants.map((person, personIndex) => (
                  <ExpertiseRdvPersonLine
                    key={`${person.id}-${index}`}
                    person={person}
                    personIndex={personIndex}
                  />
                ))
              }
            </CardContent>
          </Collapse>
      }
    </Card>
  )
}

export default ExpertiseRdvCard
