/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { isValidString } from './isValidString'

/* Component imports -------------------------------------------------------- */
import { toast } from 'react-toastify'

/* Config imports ----------------------------------------------------------- */
import defaultConfig from 'config/default.config.json'

/* Config declaration ------------------------------------------------------- */
type ConfigStringValue = {
  description: string;
  valeur: string;
}

type ConfigMultipleStringValue = {
  description: string;
  valeur: string[];
}

interface Config {
  api: {
    url: ConfigStringValue;
  };
  onglets: {
    dossier: ConfigMultipleStringValue;
    principaux: ConfigMultipleStringValue;
  };
  suiviDossier: {
    typesSuivi: ConfigMultipleStringValue;
  };
  documents: {
    typesDocuments: ConfigMultipleStringValue;
  };
}

export let apiUrl: string = defaultConfig.api.url.valeur
export let caseTabs: string[] = defaultConfig.onglets.dossier.valeur
export let mainTabs: string[] = defaultConfig.onglets.principaux.valeur
export let followUpTypes: string[] = defaultConfig.suiviDossier.typesSuivi.valeur

export async function readConfig() {
  return fetch('/app.config.json')
    .then((response) => response.json())
    .then((reponse) => {
      const config = reponse as Config
      // Api
      if (isValidString(config?.api?.url?.valeur)) {
        OpenAPI.baseUrl = config.api.url.valeur
        apiUrl = config.api.url.valeur
      } else {
        toast.error("L'url de l'api n'a pas été trouvée dans le fichier app.config.json")
      }

      // Tabs
      if (Array.isArray(config?.onglets?.dossier?.valeur)) {
        caseTabs = config.onglets.dossier.valeur
      } else {
        toast.error('Les onglets dossiers sont invalides ou manquants dans le fichier app.config.json')
      }
      if (Array.isArray(config?.onglets?.principaux?.valeur)) {
        mainTabs = config.onglets.principaux.valeur
      } else {
        toast.error('Les onglets principaux sont invalides ou manquants dans le fichier app.config.json')
      }

      // Case follow-up
      if (Array.isArray(config?.suiviDossier?.typesSuivi?.valeur)) {
        followUpTypes = config.suiviDossier.typesSuivi.valeur
      } else {
        toast.error('Les types de suivi dossier sont invalides ou manquants dans le fichier app.config.json')
      }
    })
    .catch((err) => {
      toast.error('Erreur lors de la lecture du fichier app.config.json. Le fichier semble être invalide.')
      console.error(err)
    })
}
