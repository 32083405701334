/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import {
  resetAuthInLocalStorage,
  setAuthInLocalStorage,
} from 'helpers/localStorageHelpers'

/* Type imports ---------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { LoginResponse } from 'API/__generated__/Api'
import type { RootState } from 'store/store'
import type { Nullable } from 'types/Nullable'

/* Auth Redux slice ------------------------------------ */
const authSlice = createSlice(
  {
    name: 'auth',
    initialState: {
      currentCollaborateur: null,
      token: null,
    } as Nullable<LoginResponse>,
    reducers: {
      setAuthInfo: (state, { payload }: PayloadAction<LoginResponse>) => {
        setAuthInLocalStorage(payload)
        OpenAPI.setSecurityData(payload)
        return payload
      },
      resetAuthInfo: () => {
        resetAuthInLocalStorage()
        OpenAPI.setSecurityData(null)
        return { currentCollaborateur: null, token: null }
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const {
  setAuthInfo,
  resetAuthInfo,
} = authSlice.actions

export default authSlice.reducer

export const selectAuthInfo = (state: RootState): Nullable<LoginResponse> => {
  return state.auth
}
