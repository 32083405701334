/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import defaultCaseTabs from 'helpers/defaultCaseTabs'
import { caseTabs } from 'helpers/readConfig'

/* Component imports -------------------------------------------------------- */
import {
  Badge,
  Tab,
  Tabs,
} from '@mui/material'
import RouteTitle from 'router/RouteTitle'

/* Styled components ----------------------------------- */
const NavTabsContainer = styled.div`
  display: flex;
  justify-content: center;

  span {
    margin-top: 5px;
  }
`

const ScrollableTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    @media ${(props) => props.theme.media.desktop}, ${(props) => props.theme.media.tablet} {
      justify-content: center;
    }
  }

  // Here we want the tabs to be scrollable but we want to hide the scrollbar under it
  .MuiTabs-scroller {
    overflow-x: auto !important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }

  .MuiTabs-scroller::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
`

interface TabProps {
  hasbadge: string;
}

const ResponsiveTab = styled(Tab)<TabProps>`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding: ${(props) => props.hasbadge === 'false' ? '0px 1vw !important' : ''};
  min-width: 0px !important;
`

const LabelContainer = styled.div`
  padding: 10px 10px 15px 0px;
`

/* Component declaration ------------------------------- */
interface CaseLayoutNavTabsProps {
  documentsLength: number;
}

const CaseLayoutNavTabs: React.FC<CaseLayoutNavTabsProps> = ({ documentsLength }) => {
  const { caseId } = useParams<{ caseId: string }>()
  const location = useLocation()
  const navigate = useNavigate()
  const [ tabValue, setTabValue ] = useState<number | boolean>(false)
  const [ title, setTitle ] = useState<string>('')
  const tabs = caseTabs.map((configTab) => defaultCaseTabs.find((tab) => tab.path === configTab) ?? defaultCaseTabs[0])

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    setTabValue(newTabValue)
    if (tabs[newTabValue]?.label) {
      setTitle(`${caseId} - ${tabs[newTabValue].label}`)
    }
  }, [ location.pathname ])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabValue(newValue)
    navigate(`/dossiers/${caseId}/${tabs[newValue].path}`)
  }

  return (
    <NavTabsContainer>
      <ScrollableTabs
        value={tabValue === -1 ? false : tabValue}
        onChange={handleTabChange}
      >
        {
          tabs.map((tab, index) => (
            <ResponsiveTab
              key={`${tab.path}-${index}`}
              hasbadge={Boolean(tab.path === 'documents' && documentsLength > 0).toString()}
              label={
                (tab.path === 'documents' && documentsLength > 0) ?
                  <Badge
                    badgeContent={documentsLength}
                    invisible={documentsLength <= 0}
                    color="primary"
                    anchorOrigin={
                      {
                        vertical: 'top',
                        horizontal: 'right',
                      }
                    }
                  >
                    <LabelContainer>
                      {tab.label}
                    </LabelContainer>
                  </Badge> :
                  tab.label
              }
            />
          ))
        }
      </ScrollableTabs>
      <RouteTitle title={title} />
    </NavTabsContainer>
  )
}

export default CaseLayoutNavTabs
