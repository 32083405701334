/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import EraseFiltersButton from 'components/EraseFiltersButton/EraseFiltersButton'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ---------------------------------------- */
import type {
  CodeLabel,
  NatureSinistre,
} from 'API/__generated__/Api'
import type { SegmentedButtonOptionType } from 'types/SegmentedButton'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const SelectFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const VisualModeContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: 25%;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: -15px;
  }
`

/* Component declaration ------------------------------- */
interface NotificationsFiltersProps {
  size?: string;
  companyList: CodeLabel[];
  isFetchingCompanyList: boolean;
  disasterNatureList: NatureSinistre[];
  isFetchingDisasterNatureList: boolean;
  agencyList: CodeLabel[];
  isFetchingAgencyList: boolean;
  visualMode: 'timeline' | 'table' | undefined;
  onChangeVisualMode: (value: 'timeline' | 'table') => void;
  onEraseFilterClick: () => void;
}

const NotificationsFilters: React.FC<NotificationsFiltersProps> = ({
  size = '',
  companyList,
  isFetchingCompanyList,
  disasterNatureList,
  isFetchingDisasterNatureList,
  agencyList,
  isFetchingAgencyList,
  visualMode,
  onChangeVisualMode,
  onEraseFilterClick,
}) => {

  const createNewVisualModeOptions = (): SegmentedButtonOptionType[] => {
    return [
      { value: 'table', label: 'Tableau' },
      { value: 'timeline', label: 'Timeline' },
    ].map((option) => ({ ...option, ref: React.createRef() }))
  }

  return (
    <>
      <SelectFilterContainer>
        <div>
          <FormBoldTitle>
            Compagnie
          </FormBoldTitle>
          <Field
            component={Select}
            name="filters.compagnie"
            displayEmpty
            disabled={isFetchingCompanyList}
            size={size}
          >
            <MenuItem value="">
              Sélectionner
            </MenuItem>
            {
              companyList.map((company, index) => (
                <MenuItem
                  value={company.code}
                  key={`${company.code}-${index}`}
                >
                  {company.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <div>
          <FormBoldTitle>
            Type de sinistre
          </FormBoldTitle>
          <Field
            component={Select}
            name="filters.natureSinistre"
            displayEmpty
            disabled={isFetchingDisasterNatureList}
            size={size}
          >
            <MenuItem value="">
              Sélectionner
            </MenuItem>
            {
              disasterNatureList.map((value, index) => (
                <MenuItem
                  value={value.code}
                  key={`${value.code}-${index}`}
                >
                  {value.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <div>
          <FormBoldTitle>
            Agence traitante
          </FormBoldTitle>
          <Field
            component={Select}
            name="filters.agence"
            displayEmpty
            disabled={isFetchingAgencyList}
            size={size}
          >
            <MenuItem value="">
              Sélectionner
            </MenuItem>
            {
              agencyList.map((value, index) => (
                <MenuItem
                  value={value.code}
                  key={`${value.code}-${index}`}
                >
                  {value.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <EraseFiltersButton onClick={onEraseFilterClick} />
      </SelectFilterContainer>
      <VisualModeContainer>
        <FormBoldTitle>
          Mode d'affichage
        </FormBoldTitle>
        <SegmentedButtons
          options={createNewVisualModeOptions()}
          setSelectedOption={(value) => onChangeVisualMode(value as 'timeline' | 'table')}
          selectedOption={visualMode}
        />
      </VisualModeContainer>
    </>
  )
}

export default NotificationsFilters
