/* Type imports ---------------------------------------- */
import type { ChipColor } from 'types/ChipColor'
import { TypeNotificationUtilisateur } from 'API/__generated__/Api'

/* getNotificationStatusColor helper function ---------- */
export const getNotificationStatusColor = (code: TypeNotificationUtilisateur): ChipColor => {
  switch (code) {
    case TypeNotificationUtilisateur.AccuseReception:
      return 'blue'
    case TypeNotificationUtilisateur.PriseDeRendezVous:
      return 'purple'
    case TypeNotificationUtilisateur.PreRapport:
      return 'orange'
    default:
      return 'salmon'
  }
}
