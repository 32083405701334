/* Framework imports -------------------------------------------------------- */
import { useMemo } from 'react'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import {
  persistor,
  store,
} from 'store/store'
import { getAuthFromLocalStorage } from 'helpers/localStorageHelpers'
import {
  setAuthInfo,
  selectAuthInfo,
  resetAuthInfo,
} from './slices/authSlice'

/* Type imports ---------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from 'store/store'
import type { LoginResponse } from 'API/__generated__/Api'
import type { Nullable } from 'types/Nullable'

/* Generic Redux store hooks --------------------------- */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector

/* Specific Redux store hooks -------------------------- */
export const useAuthInfo = (): Nullable<LoginResponse> | null => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Nullable<LoginResponse> = useAppSelector(
    selectAuthInfo,
    (pLeft: Nullable<LoginResponse>, pRight: Nullable<LoginResponse>): boolean => {
      return pLeft.currentCollaborateur?.id === pRight.currentCollaborateur?.id &&
        pLeft.token === pRight.token
    },
  )

  return useMemo<Nullable<LoginResponse> | null>((): Nullable<LoginResponse> | null => {
    if (lLoginInfo.token === null) {
      const lLocalStorageAuthInfo = getAuthFromLocalStorage()

      if (lLocalStorageAuthInfo !== null) {
        dispatch(setAuthInfo(lLocalStorageAuthInfo))
        return lLocalStorageAuthInfo
      }

      return null
    }

    return lLoginInfo
  }, [ lLoginInfo, dispatch ])
}

export const onLogoutReset = () => {
  persistor.pause()
  persistor.flush().then(() => {return persistor.purge()}).catch(console.error)
  store.dispatch(resetAuthInfo())
}
