/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { CheckRounded } from '@mui/icons-material'

/* Type declaration ------------------------------------ */
import type { JalonSimplifie } from 'API/__generated__/Api'
import type { Size2D } from 'types/Vectors'

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const WorkflowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
  margin: 0px -5px;

  @media ${(props) => props.theme.media.desktop} {
    margin: 0px -15px;
  }
`

interface JalonProps {
  done: 'true' | 'rdv' | 'false';
}

const JalonContainer = styled.div<JalonProps>`
  min-width: 120px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-align: center;
  align-self: end;
  font-size: .8rem;
  font-weight: bold;
  height: 50px;
  color: ${(props) => props.done === 'true' ? props.theme.palette.primary.main : props.done === 'rdv' ? props.theme.colors.orange : 'grey'};
`

const CheckedIcon = styled(CheckRounded)<JalonProps>`
  color: ${(props) => props.done === 'true' ? props.theme.palette.primary.main : props.theme.colors.orange};
  font-size: 20px;
`

const ArrowSvg = styled.svg<JalonProps>`
  width: 100%;
  height: 6px;

  path {
    fill: ${(props) => props.done === 'true' ? props.theme.palette.primary.main : props.done === 'rdv' ? props.theme.colors.orange : 'lightgrey'};
  }
`

/* Component declaration ------------------------------- */
interface CaseLayoutSummaryWorkflowProps {
  workflow: JalonSimplifie[];
  windowResize: Size2D;
}

const CaseLayoutSummaryWorkflow: React.FC<CaseLayoutSummaryWorkflowProps> = ({
  workflow,
  // windowResize,
}) => {

  const calculatePath = (id: string) => {
    const svg = document.getElementById(id)?.clientWidth ?? 126

    return `M0 0H${svg - 4}L${svg} 3L${svg - 4} 6H0L4 3L0 0Z`
  }

  const calculateViewBox = (id: string) => {
    const svg = document.getElementById(id)?.clientWidth ?? 126

    return `0 0 ${svg - 1} 6`
  }

  return (
    <WorkflowContainer>
      {
        workflow.map((jalon, index) => (
          <JalonContainer
            key={`${jalon.libelle}-${index}`}
            done={jalon.fait ? 'true' : jalon.date ? 'rdv' : 'false'}
          >
            {jalon.fait && <CheckedIcon done="true" />}
            {jalon.libelle}
            <ArrowSvg
              id={`${jalon.libelle}-${index}`}
              done={jalon.fait ? 'true' : jalon.date ? 'rdv' : 'false'}
              viewBox={calculateViewBox(`${jalon.libelle}-${index}`)}
            >
              <path d={calculatePath(`${jalon.libelle}-${index}`)} />
            </ArrowSvg>
          </JalonContainer>
        ))
      }
    </WorkflowContainer>
  )
}

export default CaseLayoutSummaryWorkflow
