/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useGetCaseRendezVousListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Switch,
} from '@mui/material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import ExpertiseRdvCard from './ExpertiseRdvComponents/ExpertiseRdvCard'

/* Type imports ---------------------------------------- */

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const SwitchContainer = styled.div`
  font-size: 1rem;
  text-transform: none;
  width: auto;
  font-weight: normal;
`

interface RdvContainerProps {
  last: boolean;
}

const RdvContainer = styled.div<RdvContainerProps>`
  margin-bottom: ${(props) => props.last ? '20px' : 'Opx'};
`

/* Component declaration ------------------------------- */
interface ExpertiseRdvPageProps {}

const ExpertiseRdvPage: React.FC<ExpertiseRdvPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()
  const [ displayCanceled, setDisplayCanceled ] = useState<boolean>(false)

  const {
    currentData: rendezVousList = [],
    isFetching: isFetchingRendezVous,
  } = useGetCaseRendezVousListQuery({ dossier: caseId ?? '', avecAnnules: true })

  const filteredRDVList = useMemo(() => rendezVousList.filter((rdv) => displayCanceled ? true : !rdv.annule).sort((a, b) => b.dateDebut.localeCompare(a.dateDebut)), [ rendezVousList, displayCanceled ])

  return (
    <div>
      <LargeTitle>
        RDV expertise
        <SwitchContainer>
          <Switch
            checked={displayCanceled}
            color="secondary"
            onChange={(e) => setDisplayCanceled(e.target.checked)}
          />
          Afficher les RDV annulés
        </SwitchContainer>
      </LargeTitle>
      {isFetchingRendezVous && <CircularProgress />}
      {filteredRDVList.length === 0 && !isFetchingRendezVous && 'Aucun rendez-vous'}
      {
        filteredRDVList.map((rdv, index) => (
          <RdvContainer
            key={rdv.id}
            last={index !== filteredRDVList.length - 1}
          >
            <ExpertiseRdvCard
              index={index}
              rdv={rdv}
            />
          </RdvContainer>
        ))
      }
    </div>
  )
}

export default ExpertiseRdvPage
