/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'
import { useIsMobile } from 'helpers/hooks/useIsMedia'
import { useGetInternalExchangeListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Collapse,
} from '@mui/material'
import { CreateOutlined } from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import CloseButton from 'components/CloseButton/CloseButton'
import MessagesCard from './MessagesComponents/MessagesCard'
import MessagesNewMessage from './MessagesComponents/MessagesNewMessage'
import MessagesAnswerMessage from './MessagesComponents/MessagesAnswerMessage'

/* Type imports ---------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`

const InterfaceContainer = styled.div`
  max-height: calc(100vh - 290px);
  position: relative;
  overflow-y: auto;

  @media ${(props) => props.theme.media.mobile.landscape} {
    max-height: calc(100vh - 150px);
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    max-height: calc(100vh - 190px);
  }
`

const NewMessageContainer = styled.div`
  position: sticky;
  bottom: -1px;
  width: 100%;
`

const CardContent = styled.div`
  padding: 15px;

  @media ${(props) => props.theme.media.mobile.main} {
    padding: 10px;
  }
`

const NewMessageButton = styled(Button)`
  margin: -10px 0px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin: -5px 0px -5px;
  }
`

const NewMessageIcon = styled(CreateOutlined)`
  margin-left: 5px;
`

/* Component declaration ------------------------------- */
interface MessagesPageProps {}

const MessagesPage: React.FC<MessagesPageProps> = () => {
  const authInfo = useAuthInfo()
  const isMobile = useIsMobile()
  const { caseId } = useParams<{ caseId: string }>()
  const [ expanded, setExpanded ] = useState<boolean>(false)
  const [ reply, setReply ] = useState<EchangeInterne | null>(null)

  const {
    currentData: internalExchanges = [],
    isFetching: isFetchingInternalExchanges,
  } = useGetInternalExchangeListQuery({
    dossier: caseId ?? '',
  })

  useEffect(() => {
    if (!isFetchingInternalExchanges) {
      const unreadMessage = document.getElementById('message-unread')
      const interfaceContainer = document.getElementById('interface-container')
      if (unreadMessage === null) {
        interfaceContainer?.scroll({ top: interfaceContainer?.scrollHeight ?? 0, behavior: 'smooth' })
      } else {
        unreadMessage.scrollIntoView({ block: 'start', inline: 'nearest', behavior: isMobile ? 'auto' : 'smooth' })
      }
    }
  }, [ internalExchanges, isFetchingInternalExchanges, isMobile ])

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const isMe = (id: string) => id === authInfo?.currentCollaborateur?.refAnnuaire.refComplete

  const onReplyClick = (message: EchangeInterne) => {
    setExpanded(false)
    setReply(message)
  }

  const handleClose = () => {
    setExpanded(false)
    setReply(null)
  }

  return (
    <div>
      <LargeTitle>
        Messages
      </LargeTitle>
      <LoadingOverlay isLoading={isFetchingInternalExchanges}>
        <InterfaceContainer id="interface-container">
          {
            [ ...internalExchanges ].sort((a, b) => a.date.localeCompare(b.date)).map((message, index) => (
              <MessageContainer
                key={`${message.id}-${index}`}
                id={`message-${isMe(message.destinataire.id) && !message.lu ? 'unread' : 'read'}`}
              >
                <MessagesCard
                  myId={authInfo?.currentCollaborateur?.refAnnuaire.refComplete ?? ''}
                  message={message}
                  onReply={() => onReplyClick(message)}
                />
              </MessageContainer>
            ))
          }
          <NewMessageContainer>
            <Card>
              <CardContent>
                {
                  !expanded && reply === null ?
                    <NewMessageButton onClick={handleExpandClick}>
                      Écrire un nouveau message...
                      <NewMessageIcon />
                    </NewMessageButton> :
                    <CloseButton handleClose={handleClose} />
                }
                <Collapse
                  in={expanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <MessagesNewMessage
                    caseId={caseId ?? ''}
                    setExpanded={setExpanded}
                  />
                </Collapse>
                <Collapse
                  in={reply !== null}
                  timeout="auto"
                  unmountOnExit
                >
                  {
                    reply !== null &&
                      <MessagesAnswerMessage
                        message={reply}
                        closeReply={() => setReply(null)}
                      />
                  }
                </Collapse>
              </CardContent>
            </Card>
          </NewMessageContainer>
        </InterfaceContainer>
      </LoadingOverlay>
    </div>
  )
}

export default MessagesPage
