/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */

/* Styled components ----------------------------------- */
export const Person = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    align-items: start;
  }
`

export const Infos = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150px auto 125px;
  width: 100%;
  gap: 2%;

  @media ${(props) => props.theme.media.desktop} {
    grid-template-columns: 30% auto 125px;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`

export const Role = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: inline;
    font-size: .8rem;
    margin-bottom: 5px;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    display: flex;
  }
`

export const Chip = styled.div`
  min-width: 95px;
`
