/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'
import defaultCaseTabs from 'helpers/defaultCaseTabs'
import defaultMainTabs from 'helpers/defaultMainTabs'
import {
  caseTabs,
  mainTabs,
} from 'helpers/readConfig'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
import CaseLayout from 'layouts/CaseLayout/CaseLayout'
// Main Pages
import LoginPage from 'pages/AuthPages/LoginPage/LoginPage'
import ForgotPasswordPage from 'pages/AuthPages/ForgotPasswordPage/ForgotPasswordPage'
import ResetPasswordPage from 'pages/AuthPages/ResetPasswordPage/ResetPasswordPage'
import ExternalLoginPage from 'pages/AuthPages/ExternalLoginPage/ExternalLoginPage'
import GexsiLoginPage from 'pages/AuthPages/GexsiLoginPage/GexsiLoginPage'
import Nomad9LoginPage from 'pages/AuthPages/Nomad9LoginPage/Nomad9LoginPage'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import MyCasesPage from 'pages/MyCasesPage/MyCasesPage'
import MyProfilePage from 'pages/MyProfilePage/MyProfilePage'
import NotificationsPage from 'pages/NotificationsPage/NotificationsPage'

/* Type imports ---------------------------------------- */
import { LoginContextType } from 'API/__generated__/Api'

/* Component declaration ------------------------------- */
interface AuthRouterProps {}

const Router: React.FC<AuthRouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log('[DEBUG] authInfo :', authInfo)

  const isExternalLogin = useMemo(() => authInfo?.currentCollaborateur?.loginContext?.type !== LoginContextType.Complete, [ authInfo ])

  const getCaseTabs = () => {
    return caseTabs.map((configTab) => {
      const tab = defaultCaseTabs.find((tab) => tab.path === configTab)

      return tab ?
        <Route
          key={tab.path}
          path={tab.path}
          element={<tab.component props={{}} />}
        /> :
        null
    })
  }

  const getMainTabs = () => {
    return mainTabs.map((configTab) => {
      const tab = defaultMainTabs.find((tab) => tab.path === configTab)

      return tab ?
        <Route
          key={tab.path}
          path={tab.path}
          element={<tab.component props={{}} />}
        /> :
        null
    })
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo !== null ?
            <MainLayout /> :
            <Navigate
              to="/connexion"
              replace
            />
        }
      >
        <Route path="dossiers">
          <Route
            index
            element={<MyCasesPage />}
          />
          <Route
            path=":caseId"
            element={<CaseLayout externalLogin={isExternalLogin} />}
          >
            <Route
              index
              element={
                <Navigate
                  to={defaultCaseTabs.find((tab) => tab.path === caseTabs[0])?.path ?? 'suivi-dossier'}
                  replace
                />
              }
            />
            {getCaseTabs()}
          </Route>
        </Route>
        {
          !isExternalLogin &&
            <>
              {getMainTabs()}
              <Route
                path="mon-compte"
                element={<MyProfilePage />}
              />
              <Route
                path="notifications"
                element={<NotificationsPage />}
              />
            </>
        }
        <Route
          index
          element={
            <Navigate
              to={isExternalLogin ? `/dossiers/${authInfo?.currentCollaborateur?.loginContext.refDossier?.refComplete}` : '/dossiers'}
              replace
            />
          }
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route
        element={
          authInfo === null || isExternalLogin ?
            <AuthLayout /> :
            <Navigate
              to="/"
              replace
            />
        }
      >
        <Route
          path="/connexion"
          element={<LoginPage />}
        />
        <Route
          path="/mot-de-passe-oublie"
          element={<ForgotPasswordPage />}
        />
        <Route
          path="/reinitialiser-le-mot-de-passe"
          element={<ResetPasswordPage />}
        />
      </Route>
      <Route
        path="/Account/LoginEpassExterieur"
        element={<ExternalLoginPage />}
      />
      <Route
        path="/Account/LoginGexsi/:caseId"
        element={<GexsiLoginPage />}
      />
      <Route
        path="/PortailAssureur/:caseId"
        element={<Nomad9LoginPage />}
      />
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
