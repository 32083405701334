/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor'

/* Type imports ---------------------------------------- */
import type { ImageEditor } from 'types/ImageEditor'

/* Type declarations ----------------------------------- */

/* Internal variables ---------------------------------- */
const translations = {
  save: 'Enregistrer',
  resize: 'Modifier la taille',
  resizeTab: 'Modifier la taille',
  annotateTab: 'Annoter',
  flipX: 'Retourner horizontalement',
  flipY: 'Retourner verticalement',
  unFlipX: 'Retourner horizontalement',
  unFlipY: 'Retourner verticalement',
}

const defaultSourceUrl: string = 'https://scaleflex.cloudimg.io/v7/demo/river.png'

/* Styled components ----------------------------------- */
const ImageProcessorContainer = styled.div`
  height: 50vh;
`

/* Component declaration ------------------------------- */
interface ImageProcessorProps {
  source: string;
  imageEditorRef?: React.MutableRefObject<ImageEditor | undefined>;
}

const ImageProcessor: React.FC<ImageProcessorProps> = ({
  source = defaultSourceUrl,
  imageEditorRef,
}) => {

  const downloadBase64File = (base64Data: string, fileName: string): void => {
    const downloadLink = document.createElement('a')
    downloadLink.href = base64Data
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <ImageProcessorContainer>
      {
        isValidString(source) &&
          <FilerobotImageEditor
            getCurrentImgDataFnRef={imageEditorRef}
            previewPixelRatio={0}
            savingPixelRatio={0}
            source={source}
            showCanvasOnly={true}
            language="fr"
            translations={translations}
            onSave={(editedImageObject): void => downloadBase64File(editedImageObject.imageBase64 ?? '', editedImageObject.fullName ?? editedImageObject.name)}
            annotationsCommon={{ fill: '#ff0000' }}
            Text={{ text: 'Votre texte...' }}
            Rotate={{ angle: 90, componentType: 'slider' }}
            Crop={
              {
                ratio: 1,
                noPresets: true,
              }
            }
            tabsIds={[ TABS.ADJUST ]} // or {['Adjust', 'Annotate', 'Watermark']}
            defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.CROP}
          />
      }
    </ImageProcessorContainer>
  )
}

export default ImageProcessor
