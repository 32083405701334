/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setAuthInfo } from 'store/slices/authSlice'
import { useAuthenticateExternalMutation } from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import RouteTitle from 'router/RouteTitle'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'

/* Type imports ---------------------------------------- */
import type { LoginResponse } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const Message = styled.div`
  text-align: center;
`

/* Component declaration ------------------------------- */
interface ExternalLoginPageProps {}

const ExternalLoginPage: React.FC<ExternalLoginPageProps> = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(true)
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  const [
    tryLogin,
  ] = useAuthenticateExternalMutation()

  useEffect(() => {
    const token = location.search.split('?stringEncrypt=')[1]
    if (isValidString(token)) {
      tryLogin({ token })
        .then((pResult) => {
          setIsSubmitting(false)

          if (isApiResponse<LoginResponse>(pResult)) {
            dispatch(setAuthInfo((pResult.data)))
            navigate(`/dossiers/${pResult.data.currentCollaborateur.loginContext.refDossier?.refComplete}`)
          } else {
            setErrorMessage('Erreur lors de la connexion.')
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          setErrorMessage('Erreur lors de la connexion.')
          console.error(error)
        })
    }
  }, [ location ])

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Connexion externe" />
      <AuthContainer>
        <AuthWelcomeMessage>
          {'Bienvenue dans '}
          <b>
            ePass
          </b>
        </AuthWelcomeMessage>
        {
          isSubmitting &&
            <Message>
              Veuillez patienter pendant que nous vous connectons automatiquement...
              <br />
              <br />
              <CircularProgress />
            </Message>
        }
        {
          isValidString(errorMessage) && (
            <AuthErrorMessage>
              {errorMessage}
            </AuthErrorMessage>
          )
        }
      </AuthContainer>
    </BaseLayout>
  )
}

export default ExternalLoginPage
