/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import ExpertiseRdvStakeholderLine from './ExpertiseRdvStakeholderLine'
import ExpertiseRdvDownloadButton from './ExpertiseRdvDownloadButton'
import {
  Chip as ChipContainer,
  Infos as InfoContainer,
  Person,
  Role as RoleContainer,
} from './ExpertiseRdvLineComponents'

/* Type imports ---------------------------------------- */
import type { TiersParticipant } from 'API/__generated__/Api'
import { TypePersonne } from 'API/__generated__/Api'

/* Type declarations ----------------------------------- */

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const PersonContainer = styled(Person)`
  margin-top: 40px;
`

interface StakeholderContainerProps {
  last: boolean;
}

const StakeholderContainer = styled.div<StakeholderContainerProps>`
  margin-top: 10px;
  margin-bottom: ${(props) => props.last ? '0px' : '20px'};
`

/* Component declaration ------------------------------- */
interface ExpertiseRdvPersonLineProps {
  person: TiersParticipant;
  personIndex: number;
}

const ExpertiseRdvPersonLine: React.FC<ExpertiseRdvPersonLineProps> = ({
  person,
  personIndex,
}) => {

  return (
    <div>
      <PersonContainer>
        <InfoContainer>
          <b>
            {`${person.nom} ${person.prenom} - ${person.type === TypePersonne.Assure ? 'Assuré' : `Tiers ${personIndex}`}`}
          </b>
          <RoleContainer>
            <div>
              {`Qualité : ${person.qualite?.libelle ?? ''}`}
            </div>
            <div>
              {`Police : ${person.police}`}
            </div>
          </RoleContainer>
          <ExpertiseRdvDownloadButton
            url={person.convocation.url}
            name={person.nom}
          />
        </InfoContainer>
        <ChipContainer>
          <ColoredSquareChip
            color={person.convocation.present? 'green' : 'red'}
            smaller
          >
            {person.convocation.present? 'Présent' : 'Non présent'}
          </ColoredSquareChip>
        </ChipContainer>
      </PersonContainer>
      {
        person.intervenants.map((stakeholder, index) => (
          <StakeholderContainer
            key={`${stakeholder.id}-${index}`}
            last={index === person.intervenants.length - 1}
          >
            <ExpertiseRdvStakeholderLine person={stakeholder} />
          </StakeholderContainer>
        ))
      }
    </div>
  )
}

export default ExpertiseRdvPersonLine
