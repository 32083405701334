/* Framework imports -------------------------------------------------------- */
import React, { useRef } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import ImageProcessor from 'components/ImageProcessor/ImageProcessor'

/* Type imports ---------------------------------------- */
import type { ImageEditor } from 'types/ImageEditor'

/* Type declarations ----------------------------------- */

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
  min-height: 50vh;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

/* Component declaration ------------------------------- */
interface MyProfileAvatarUploaderProps {
  open: boolean;
  handleClose: (closeQuickActionsModal?: boolean) => void;
  image: string;
  setImageFile: (file: File) => void;
}

const MyProfileAvatarUploader: React.FC<MyProfileAvatarUploaderProps> = ({
  open,
  handleClose,
  image,
  setImageFile,
}) => {
  const imageEditorRef = useRef<ImageEditor | undefined>()

  const onClick = () => {
    const { current: saveFunction } = imageEditorRef
    if (!saveFunction) return

    const imageData = saveFunction().imageData
    fetch(imageData.imageBase64)
      .then((res) => res.blob())
      .then((blob) => setImageFile(new File([ blob ], imageData.name, { type: imageData.mimeType })))
      .catch(console.error)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitleContainer>
        Nouvelle photo de profil
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        <ImageProcessor
          source={image}
          imageEditorRef={imageEditorRef}
        />
      </DialogContentContainer>
      <DialogActionContainer>
        <LongButton
          onClick={onClick}
          variant="contained"
        >
          Valider
        </LongButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default MyProfileAvatarUploader
