/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ---------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'

/* Type declarations ----------------------------------- */
export interface RouterHistoryState {
  lastPath: string | null;
}

/* RouterHistory Redux slice ---------------------------- */
const routerHistorySlice = createSlice(
  {
    name: 'routerHistory',
    initialState: {
      lastPath: '/dossiers',
    } as RouterHistoryState,
    reducers: {
      setRouterLastPath: (state, { payload }: PayloadAction<string>) => {
        state.lastPath = payload
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const { setRouterLastPath } = routerHistorySlice.actions

export default routerHistorySlice.reducer

export const getRouterLastPath = (state: RootState): string | null => {
  return state.routerHistory.lastPath
}
