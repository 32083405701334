/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */

/* Type imports ---------------------------------------- */

/* Component declaration ------------------------------- */
interface ScrollableFiltersContainerProps {}

const ScrollableFiltersContainer = styled.div<ScrollableFiltersContainerProps>`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  box-sizing: border-box;

  gap: .5rem;

  padding-top: .5rem;
  padding-bottom: .5rem;

  /* Scrollbar size */
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.164);
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e7e7e7;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #cccccc;
  }
`

export default ScrollableFiltersContainer
