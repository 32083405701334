/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import PersonIcon from '@mui/icons-material/Person'
import CustomIconButtonContainer from 'components/IconButtons/CustomIconButton/CustomIconButtonContainer'

/* Type imports ---------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  object-fit: cover;
  object-position: 50% 0;
`

/* Component declaration ------------------------------- */
interface MiniAvatarRendererProps extends ButtonProps {
  url: string;
}

const MiniAvatarRenderer: React.FC<MiniAvatarRendererProps> = ({ url, ...rest }) => {
  const [ isValidImage, setIsValidImage ] = useState<boolean>(false)

  useEffect(() => {
    if (isValidString(url)) {
      setIsValidImage(true)
    }
  }, [ url ])

  const onImageError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    currentTarget.onerror = null // prevents looping
    setIsValidImage(false)
  }

  return (
    <CustomIconButtonContainer {...rest}>
      {
        isValidImage ?
          <Image
            src={url}
            alt="photo de profil"
            onError={onImageError}
          /> :
          <PersonIcon />
      }
    </CustomIconButtonContainer>
  )
}

export default MiniAvatarRenderer
