/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostResetPasswordMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'

/* Type imports ---------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { ResetEpassPasswordAuthentificationCreateParams } from 'API/__generated__/Api'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Types declaration ----------------------------------- */
const ForgotPasswordFormSchema = Yup.object().shape({
  mail: Yup.string().email("L'email est invalide").required("L'email est invalide"),
})

/* Styled components ----------------------------------- */

/* Component declaration ------------------------------- */
interface ForgotPasswordPageProps {}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = () => {
  const navigate = useNavigate()

  const [
    resetPassword,
  ] = usePostResetPasswordMutation()

  const onSubmit = (values: ResetEpassPasswordAuthentificationCreateParams, { setSubmitting }: FormikHelpers<ResetEpassPasswordAuthentificationCreateParams> ): void | Promise<void> => {
    const onFinish = (response: ApiResponse<void>): void => {
      setSubmitting(false)
      if (!isApiError(response)) {
        toast.success(`Un mail à été envoyé pour réinitialiser votre mot de passe.`)
      } else {
        toast.error(`Une erreur est survenue lors de l'envoi du mail. Veuillez verifier votre adresse e-mail.`)
      }
    }

    resetPassword(values).then(onFinish).catch(console.error)
  }

  const formikForm = useForm<ResetEpassPasswordAuthentificationCreateParams>(
    {
      initialValues: {
        mail: '',
        redirectUrl: `${window.location.origin}/reinitialiser-le-mot-de-passe`,
      },
      onSubmit: onSubmit,
      validationSchema: ForgotPasswordFormSchema,
    },
  )

  const onCancelClick = () => {
    navigate('/connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          Mot de passe oublié ?
        </AuthWelcomeMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              E-mail
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="E-mail"
              name="mail"
              autoComplete="mail"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            {
              formikForm.isSubmitting ?
                `Envoi du mail en cours...` :
                `Réinitialiser le mot de passe`
            }
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onCancelClick}
          >
            Annuler
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default ForgotPasswordPage
