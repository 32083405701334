/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAppDispatch } from 'store/hooks'
import { setCaseListPeriod } from 'store/slices/caseListFilterSlice'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import EraseFiltersButton from 'components/EraseFiltersButton/EraseFiltersButton'

/* Type imports ---------------------------------------- */
import type {
  CodeLabel,
  NatureSinistre,
} from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const SelectFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const PeriodContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: 25%;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: -15px;
  }
`

/* Component declaration ------------------------------- */
interface MyCasesFiltersProps {
  size?: string;
  companyList: CodeLabel[];
  isFetchingCompanyList: boolean;
  disasterNatureList: NatureSinistre[];
  isFetchingDisasterNatureList: boolean;
  agencyList: CodeLabel[];
  isFetchingAgencyList: boolean;
  periodList: {value: number | undefined; label: string}[];
  onEraseFilterClick: () => void;
}

const MyCasesFilters: React.FC<MyCasesFiltersProps> = ({
  size = '',
  companyList,
  isFetchingCompanyList,
  disasterNatureList,
  isFetchingDisasterNatureList,
  agencyList,
  isFetchingAgencyList,
  periodList,
  onEraseFilterClick,
}) => {
  const dispatch = useAppDispatch()

  return (
    <>
      <SelectFilterContainer>
        <div>
          <FormBoldTitle>
            Compagnie
          </FormBoldTitle>
          <Field
            component={Select}
            name="Compagnie"
            displayEmpty
            disabled={isFetchingCompanyList}
            size={size}
          >
            <MenuItem value="">
              Sélectionner
            </MenuItem>
            {
              companyList.map((company, index) => (
                <MenuItem
                  value={company.code}
                  key={`${company.code}-${index}`}
                >
                  {company.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <div>
          <FormBoldTitle>
            Type de sinistre
          </FormBoldTitle>
          <Field
            component={Select}
            name="NatureSinistre"
            displayEmpty
            disabled={isFetchingDisasterNatureList}
            size={size}
          >
            <MenuItem value="">
              Sélectionner
            </MenuItem>
            {
              disasterNatureList.map((value, index) => (
                <MenuItem
                  value={value.code}
                  key={`${value.code}-${index}`}
                >
                  {value.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <div>
          <FormBoldTitle>
            Agence traitante
          </FormBoldTitle>
          <Field
            component={Select}
            name="Agence"
            displayEmpty
            disabled={isFetchingAgencyList}
            size={size}
          >
            <MenuItem value="">
              Sélectionner
            </MenuItem>
            {
              agencyList.map((value, index) => (
                <MenuItem
                  value={value.code}
                  key={`${value.code}-${index}`}
                >
                  {value.libelle}
                </MenuItem>
              ))
            }
          </Field>
        </div>
        <EraseFiltersButton onClick={onEraseFilterClick} />
      </SelectFilterContainer>
      <PeriodContainer>
        <FormBoldTitle>
          Période à afficher
        </FormBoldTitle>
        <Field
          component={Select}
          name="NombreDeJour"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dispatch(setCaseListPeriod(parseInt(e.target.value)))}
          displayEmpty
          size={size}
        >
          {
            periodList.map((value, index) => (
              <MenuItem
                value={value.value}
                key={`${value.value}-${index}`}
              >
                {value.label}
              </MenuItem>
            ),
            )
          }
        </Field>
      </PeriodContainer>
    </>
  )
}

export default MyCasesFilters
