/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import Button from '@mui/material/Button'

/* Type imports ---------------------------------------- */

/* Component declaration ------------------------------- */
const LongButton = styled(Button)`
  min-width: 150px;
`

export default LongButton
