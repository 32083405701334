/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetCaseInfosQuery,
  useGetCaseKeyDatesQuery,
  useGetGroupInfosQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import LargeTitle from 'components/LargeTitle/LargeTitle'
import DetailsInfosContainer from './DetailsComponents/DetailsInfosContainer'

/* Type imports ---------------------------------------- */
import { TypePersonne } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const LittleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  justify-content: space-between;
  overflow-wrap: anywhere;
`

/* Component declaration ------------------------------- */
interface DetailsPageProps {}

const DetailsPage: React.FC<DetailsPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId ?? '')
  const {
    currentData: keyDates,
    isFetching: isFetchingKeyDates,
  } = useGetCaseKeyDatesQuery(caseId ?? '')
  const {
    currentData: groupInfos,
    isFetching: isFetchingGroupInfos,
  } = useGetGroupInfosQuery()

  const insuredPerson = useMemo(() => caseInfos?.personnes?.find((person) => person.type === TypePersonne.Assure), [ caseInfos ])

  const getInsuredPersonName = (): string => {
    return [ insuredPerson?.nom, insuredPerson?.prenom ].join(' ')
  }

  return (
    <div>
      <LargeTitle>
        Détails
      </LargeTitle>
      <DetailsInfosContainer
        title="Informations clés du dossier"
        isFetching={isFetchingCaseInfos}
      >
        <LittleContainer>
          <InfoContainer>
            Nature
            <b>
              {caseInfos?.sinistre?.nature?.libelle}
            </b>
          </InfoContainer>
          <InfoContainer>
            Sinistre du
            <b>
              {DateUtils.APIStrToLocalDateString(caseInfos?.sinistre?.date)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Lieu du sinistre
            <b>
              {getAddress(caseInfos?.sinistre?.adresse)}
            </b>
          </InfoContainer>
        </LittleContainer>
        <LittleContainer>
          <InfoContainer>
            Caractère expertise
            <b>
              {caseInfos?.caractereExpertise?.libelle}
            </b>
          </InfoContainer>
          <InfoContainer>
            Type expertise
            <b>
              {caseInfos?.typeExpertise}
            </b>
          </InfoContainer>
          <InfoContainer>
            Type de convention
            <b>
              {caseInfos?.conventionApplicable?.libelle}
            </b>
          </InfoContainer>
        </LittleContainer>
      </DetailsInfosContainer>
      <br />
      <DetailsInfosContainer
        title="Mandants"
        isFetching={isFetchingCaseInfos}
      >
        <LittleContainer>
          <InfoContainer>
            Compagnie
            <b>
              {caseInfos?.mandants.compagnie?.libelle}
            </b>
          </InfoContainer>
          <InfoContainer>
            Délégation
            <b>
              {caseInfos?.mandants.delegation?.libelle}
            </b>
          </InfoContainer>
          <InfoContainer>
            Gestionnaire
            <b>
              {caseInfos?.mandants.gestionnaire?.libelle}
            </b>
          </InfoContainer>
        </LittleContainer>
        <LittleContainer>
          <InfoContainer>
            Ref compagnie
            <b>
              {caseInfos?.mandants.referenceCompagnie}
            </b>
          </InfoContainer>
          <InfoContainer>
            Ref délégation
            <b>
              {caseInfos?.mandants.referenceDelegation}
            </b>
          </InfoContainer>
        </LittleContainer>
      </DetailsInfosContainer>
      <br />
      <DetailsInfosContainer
        title="Dates clés du dossier"
        isFetching={isFetchingCaseInfos || isFetchingKeyDates}
      >
        <LittleContainer>
          <InfoContainer>
            Date sinistre
            <b>
              {DateUtils.APIStrToLocalDateString(caseInfos?.sinistre?.date)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date envoi mission
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.envoiMission)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date réception mission
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.receptionMission)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date Accusé Réception
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.accuseDeReception)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date 1er contact
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.premierContact)}
            </b>
          </InfoContainer>
        </LittleContainer>
        <LittleContainer>
          <InfoContainer>
            Nombre de RDV
            <b>
              {keyDates?.nombreDeRendezVous}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date 1er RDV
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.premierRendezVous)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date dernier RDV
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.dernierRendezVous)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Nombre de rapport
            <b>
              {keyDates?.nombreDeRapports}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date pré-rapport
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.preRapport)}
            </b>
          </InfoContainer>
          <InfoContainer>
            Date dernier rapport
            <b>
              {DateUtils.APIStrToLocalDateString(keyDates?.dernierRapport)}
            </b>
          </InfoContainer>
        </LittleContainer>
      </DetailsInfosContainer>
      <br />
      <DetailsInfosContainer
        title="Assuré"
        isFetching={isFetchingCaseInfos}
      >
        <LittleContainer>
          <InfoContainer>
            Assuré
            <b>
              {getInsuredPersonName()}
            </b>
          </InfoContainer>
          <InfoContainer>
            N° de Contrat
            <b>
              {insuredPerson?.police}
            </b>
          </InfoContainer>
          <InfoContainer>
            Adresse
            <b>
              {getAddress(insuredPerson?.adresse)}
            </b>
          </InfoContainer>
        </LittleContainer>
        <LittleContainer>
          <InfoContainer>
            Portable
            <b>
              <a
                href={`tel:${insuredPerson?.tel}`}
                target="_self"
              >
                {insuredPerson?.tel}
              </a>
            </b>
          </InfoContainer>
          <InfoContainer>
            Email
            <b>
              <a
                href={`mailto:${insuredPerson?.email}`}
                target="_self"
              >
                {insuredPerson?.email}
              </a>
            </b>
          </InfoContainer>
        </LittleContainer>
      </DetailsInfosContainer>
      <br />
      <DetailsInfosContainer
        title={groupInfos?.nomDuGroupe ?? ''}
        isFetching={isFetchingCaseInfos || isFetchingGroupInfos}
      >
        <LittleContainer>
          <InfoContainer>
            Cabinet
            <b>
              {caseInfos?.cabinet.libelle}
            </b>
          </InfoContainer>
          <InfoContainer>
            Ref
            <b>
              {caseInfos?.mission.reference}
            </b>
          </InfoContainer>
          <InfoContainer>
            Agence
            <b>
              {caseInfos?.agence?.nom}
            </b>
          </InfoContainer>
        </LittleContainer>
        <LittleContainer>
          <InfoContainer>
            Expert
            <b>
              {caseInfos?.mission.expert?.nom}
              {' '}
              {caseInfos?.mission.expert?.prenom}
            </b>
          </InfoContainer>
          <InfoContainer>
            Assistante
            <b>
              {caseInfos?.mission.assistante?.nom}
              {' '}
              {caseInfos?.mission.assistante?.prenom}
            </b>
          </InfoContainer>
        </LittleContainer>
      </DetailsInfosContainer>
    </div>
  )
}

export default DetailsPage
