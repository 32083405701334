/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { EditRounded } from '@mui/icons-material'
import VignetteRenderer from './VignetteRenderer'
import ImageHoverContainer from './ImageHoverContainer'
import PlaceholderImage from './PlaceholderImage'

/* Type imports ---------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Internal variables ---------------------------------- */

/* Styled components ----------------------------------- */
const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .edit-icon {
    position: absolute;
    display: none;
    pointer-events: none;
  }

  :hover {
    .edit-icon {
      display: flex;
    }
  }
`

/* Component declaration ------------------------------- */
interface AvatarUploadRendererProps {
  isValid?: boolean;
  document?: PieceJointe;
  file?: File;
  width: string;
  height: string;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AvatarUploadRenderer: React.FC<AvatarUploadRendererProps> = ({ onClick, isValid = true, ...props }) => {

  return (
    <label htmlFor="avatar-uploader">
      <input
        accept="image/*"
        id="avatar-uploader"
        type="file"
        hidden
        onChange={onClick}
      />
      <AvatarContainer>
        <ImageHoverContainer>
          {
            isValid ?
              <VignetteRenderer {...props} /> :
              <PlaceholderImage {...props} />
          }
        </ImageHoverContainer>
        <EditRounded
          color="info"
          className="edit-icon"
          fontSize="large"
        />
      </AvatarContainer>
    </label>

  )
}

export default AvatarUploadRenderer
