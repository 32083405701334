/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Checkbox,
  Link,
} from '@mui/material'
import DocumentsActionButtons from './DocumentsActionButtons'

/* Type imports ---------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Internal Variables ---------------------------------- */

/* Styled components ----------------------------------- */
interface CardContainerProps {
  selected: boolean;
}

const CardContainer = styled(Card)<CardContainerProps>`
  margin: 10px 0px;
  border: ${(props) => props.selected ? `2px solid ${props.theme.palette.primary.main} !important` : 'none'};
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const FileData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`

const SelectedFile = styled(Checkbox)`
  padding: 0px;
  padding-right: 5px;
`

const TitleContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ------------------------------- */
interface DocumentsMobileCardProps {
  file: PieceJointe;
  selected: PieceJointe[];
  setSelected: (selected: PieceJointe[]) => void;
}

const DocumentsMobileCard: React.FC<DocumentsMobileCardProps> = ({
  file,
  selected,
  setSelected,
}) => {

  const handleChange = () => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (selected.some((sel) => sel.id === file.id)) {
      setSelected(selected.filter((sel) => sel.id !== file.id))
    } else {
      setSelected([ ...selected, file ])
    }
  }

  return (
    <CardContainer
      selected={selected.some((sel) => sel.id === file.id)}
      onClick={handleChange()}
    >
      <CardContentContainer>
        <TitleContainer>
          <Link>
            <SelectedFile
              checked={selected.some((sel) => sel.id === file.id)}
            />
            {file.libelle}
          </Link>
        </TitleContainer>
        <FileData>
          <div>
            {`Type : ${file.type}`}
            <br />
            {`Date de création : ${DateUtils.APIStrToLocalDateString(file.dateCreation)}`}
            <br />
            {`Catégorie : ${file.categorie?.libelle ?? ''}`}
          </div>
          <DocumentsActionButtons file={file} />
        </FileData>
      </CardContentContainer>
    </CardContainer>
  )
}

export default DocumentsMobileCard
