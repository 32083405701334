/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Badge,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import InsertPhotoOutlined from '@mui/icons-material/InsertPhotoOutlined'
import TaskOutlined from '@mui/icons-material/TaskOutlined'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ---------------------------------------- */
import type { ResumeDocumentsDossier } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Internal Variables ---------------------------------- */

/* Styled components ----------------------------------- */
const DocumentsIconContainers = styled.div`
  display: flex;
`

/* Component declaration ------------------------------- */
interface MyCasesDocumentsProps {
  documentsInfo?: ResumeDocumentsDossier;
  caseId: string;
  isFetching: boolean;
}

const MyCasesDocuments: React.FC<MyCasesDocumentsProps> = ({
  documentsInfo,
  caseId,
  isFetching,
}) => {
  const navigate = useNavigate()

  const handleNavigateToDocuments = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    navigate(`/dossiers/${caseId}/documents`)
  }

  return (
    <DocumentsIconContainers>
      {
        isFetching ?
          <CircularProgress /> :
          <>
            <Tooltip
              title="Pièces jointes"
              arrow
              placement="top"
            >
              <Badge
                badgeContent={documentsInfo?.nombreMedia}
                onClick={handleNavigateToDocuments()}
                color="primary"
                anchorOrigin={
                  {
                    vertical: 'top',
                    horizontal: 'left',
                  }
                }
              >
                <CustomIconButton
                  variant="outlined"
                  Icon={InsertPhotoOutlined}
                />
              </Badge>
            </Tooltip>
            <Tooltip
              title="Rapport, PV, LA"
              arrow
              placement="top"
            >
              <Badge
                badgeContent={
                  (documentsInfo?.nombreLettreAcceptation ?? 0) +
                  (documentsInfo?.nombrePriseRendezVousJuridique ?? 0) +
                  (documentsInfo?.nombreRapport ?? 0)
                }
                onClick={handleNavigateToDocuments()}
                color="primary"
                anchorOrigin={
                  {
                    vertical: 'top',
                    horizontal: 'left',
                  }
                }
              >
                <CustomIconButton
                  variant="outlined"
                  Icon={TaskOutlined}
                />
              </Badge>
            </Tooltip>
          </>
      }
    </DocumentsIconContainers>
  )
}

export default MyCasesDocuments
