/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import EraseFiltersButton from 'components/EraseFiltersButton/EraseFiltersButton'

/* Type imports ---------------------------------------- */
import type { CategoriePJ } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const SelectFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

/* Component declaration ------------------------------- */
interface DocumentsFiltersProps {
  size?: 'small' | 'medium';
  documentTypeList: string[];
  categoryList: CategoriePJ[];
  isFetchingCategoryList: boolean;
  onEraseFilterClick: () => void;
}

const DocumentsFilters: React.FC<DocumentsFiltersProps> = ({
  size = undefined,
  documentTypeList,
  categoryList,
  isFetchingCategoryList,
  onEraseFilterClick,
}) => {

  return (
    <SelectFilterContainer>
      <div>
        <FormBoldTitle>
          Type de document
        </FormBoldTitle>
        <Field
          component={Select}
          name="filters.documentType"
          displayEmpty
          size={size}
        >
          <MenuItem value="">
            Sélectionner
          </MenuItem>
          {
            documentTypeList.map((documentType, index) => (
              <MenuItem
                value={documentType}
                key={`${documentType}-${index}`}
              >
                {documentType}
              </MenuItem>
            ),
            )
          }
        </Field>
      </div>
      <div>
        <FormBoldTitle>
          Catégorie
        </FormBoldTitle>
        <Field
          component={Select}
          name="filters.category"
          disabled={isFetchingCategoryList}
          displayEmpty
          size={size}
        >
          <MenuItem value="">
            Sélectionner
          </MenuItem>
          {
            categoryList.map((value, index) => (
              <MenuItem
                value={value.categorie.code}
                key={`${value.categorie.code}-${index}`}
              >
                {value.categorie.libelle}
              </MenuItem>
            ),
            )
          }
        </Field>
      </div>
      <EraseFiltersButton onClick={onEraseFilterClick} />
    </SelectFilterContainer>
  )
}

export default DocumentsFilters
