/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'

/* Type imports ---------------------------------------- */
import type { ButtonProps } from '@mui/material'

/* Internal Variables ---------------------------------- */

/* Component declaration ------------------------------- */
const RoundedChip = styled(Button)<ButtonProps>`
  font-size: 14px;
  font-weight: normal;

  border: ${(props) => props.variant === 'contained' ? 'none' : '1px solid'} !important;
  background-color: ${(props) => props.variant === 'outlined' ? 'white !important' : ''};
  border-radius: 1rem;
  min-width: fit-content;
  height: fit-content;
`

export default RoundedChip
