/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import JSZip from 'jszip'

/* Module imports ----------------------------------------------------------- */
import { saveAs } from 'file-saver'
import { isValidString } from 'helpers/isValidString'
import {
  downloadFile,
  getCleanFilename,
} from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
  IconButton,
} from '@mui/material'
import {
  AccountBalanceOutlined,
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  CampaignOutlined,
  DescriptionOutlined,
  DownloadRounded,
  EmailOutlined,
  EventBusy,
  HistoryOutlined,
  HourglassBottomOutlined,
  InfoOutlined,
  MessageOutlined,
  ReplayOutlined,
} from '@mui/icons-material'
import ShowMoreLessText from 'components/ShowMoreLessText/ShowMoreLessText'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'

/* Type imports ---------------------------------------- */
import type { SuiviSimplifie } from 'API/__generated__/Api'
import { TypeSuivi } from 'API/__generated__/Api'

/* Types declaration ----------------------------------- */

/* Styled components ----------------------------------- */
const CardContentContainer = styled.div`
  display: grid;
  grid-template-columns: calc(22% + 10px) repeat(2, calc(39% - 75px)) 100px 40px;
  font-size: .9rem;
  padding: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 0px;
  }
`

interface InfoContainerProps {
  sideBorder?: boolean;
}

const InfoContainer = styled.div<InfoContainerProps>`
  padding: 0px ${(props) => props.sideBorder ? '20px' : 0};
  border-left: ${(props) => props.sideBorder ? '2px' : 0} solid ${(props) => props.theme.colors.grey};

  @media ${(props) => props.theme.media.mobile.landscape} {
    padding: 0px ${(props) => props.sideBorder ? '10px' : 0};
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    padding: ${(props) => props.sideBorder ? '10px' : 0} 0px;
    border-left: none;
    border-top: ${(props) => props.sideBorder ? '2px' : 0} solid ${(props) => props.theme.colors.grey};
  }
`

const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
`

const ArrowButton = styled(IconButton)`
  width: 40px;
  height: 40px;
`

const ButtonActionContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: none;
  }
`

const ButtonActionContainerMobile = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: initial;
    margin-left: auto;
  }
`

const PreviewContainer = styled.div`
  padding: 10px 15px 10px;
  background-color: #d8d8d881;
`

/* Component declaration ------------------------------- */
interface CaseFollowUpHistoryCardProps {
  history: SuiviSimplifie;
}

const CaseFollowUpHistoryCard: React.FC<CaseFollowUpHistoryCardProps> = ({ history }) => {
  const [ expanded, setExpanded ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const onDownload = () => {
    if (history.pieceJointes.length === 1) {
      const file = history.pieceJointes[0]
      downloadFile(getCleanFilename(file.libelle ?? '', file.fileName ?? ''), history.pieceJointes[0].url)
    } else {
      const zip = new JSZip()

      history.pieceJointes.forEach((file) => {
        if (!file.url) {
          return
        }
        const blobPromise = fetch(file.url).then((r) => {
          if (r.status === 200) {
            return r.blob()
          }
          throw Promise.reject(new Error(r.statusText))
        })
        zip.file(getCleanFilename(file.libelle ?? '', file.fileName ?? ''), blobPromise)
      })
      zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, `${history.libelle}_documents`)).catch(console.error)
    }
  }

  const getIcon = (code: TypeSuivi) => {
    switch (code) {
      case TypeSuivi.DocumentAlaFrappe:
        return <DescriptionOutlined />
      case TypeSuivi.Rapport:
        return <DescriptionOutlined />
      case TypeSuivi.TravailAdministratif:
        return <AccountBalanceOutlined />
      case TypeSuivi.EchangeInterne:
        return <MessageOutlined />
      case TypeSuivi.DelaisCharte:
        return <HourglassBottomOutlined />
      case TypeSuivi.RendezVous:
        return <CalendarMonthOutlined />
      case TypeSuivi.RendezVousAnnule:
        return <EventBusy />
      case TypeSuivi.Email:
        return <EmailOutlined />
      case TypeSuivi.Relance:
        return <ReplayOutlined />
      case TypeSuivi.Differe:
        return <HistoryOutlined />
      case TypeSuivi.Datable:
        return <CalendarTodayOutlined />
      case TypeSuivi.Info:
        return <InfoOutlined />
      case TypeSuivi.Systeme:
        return <CampaignOutlined />
      case TypeSuivi.Divers:
        return <MessageOutlined />
      default:
        return <InfoOutlined />
    }
  }

  return (
    <Card>
      <CardContentContainer>
        <InfoContainer>
          <TypeContainer>
            {getIcon(history.type.code)}
            {history.type.libelle}
            <ButtonActionContainerMobile>
              {
                isValidString(history.commentaire) &&
                  <ArrowButton onClick={handleExpandClick}>
                    <DropdownArrow expanded={expanded.toString()} />
                  </ArrowButton>
              }
              {
                history.pieceJointes.length > 0 &&
                  <ArrowButton onClick={onDownload}>
                    <DownloadRounded color="secondary" />
                  </ArrowButton>
              }
            </ButtonActionContainerMobile>
          </TypeContainer>
        </InfoContainer>
        <InfoContainer sideBorder>
          {history.libelle}
        </InfoContainer>
        {
          history.destinataires.length === 0 ?
            <div /> :
            <InfoContainer sideBorder>
              {`Destinataire${history.destinataires.length > 1 ? 's' : ''} : `}
              <div>
                {
                  history.destinataires.map((recipient, index) => (
                    <React.Fragment key={`${recipient}-${index}`}>
                      {recipient}
                      <br />
                    </React.Fragment>
                  ))
                }
              </div>
            </InfoContainer>
        }
        {
          !isValidString(history.statut) ?
            <div /> :
            <InfoContainer sideBorder>
              {'Statut : '}
              {history.statut}
            </InfoContainer>
        }
        <ButtonActionContainerDesktop>
          {
            isValidString(history.commentaire) &&
              <ArrowButton onClick={handleExpandClick}>
                <DropdownArrow expanded={expanded.toString()} />
              </ArrowButton>
          }
          {
            history.pieceJointes.length > 0 &&
              <ArrowButton onClick={onDownload}>
                <DownloadRounded color="secondary" />
              </ArrowButton>
          }
        </ButtonActionContainerDesktop>
      </CardContentContainer>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <PreviewContainer>
          <ShowMoreLessText content={history.commentaire} />
        </PreviewContainer>
      </Collapse>
    </Card>
  )
}

export default CaseFollowUpHistoryCard
